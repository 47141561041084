import { FC, useContext, useEffect, useState } from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import client from "../../api";
import { getErrorMessage, getStatusStyle } from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { splitLetettLetter } from "src/lib/utils";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Table, Tooltip } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  unidentDepositFilterOptions,
} from "src/lib/constants";
import { PaginationInput, User } from "src/api/types";
import {
  TransactionDataType,
  UnidentifyDepositDataType,
  CurrencyDataType,
} from "src/lib/constants/table-type";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import SearchFilter from "src/components/search-filters/unident-deposits-filter";

const UnidentifiedDepositsPage = () => {
  const [searchParams, setSearchParams] = useState<PaginationInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });
  const [searchText, setSearchText] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();

  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<UnidentifyDepositDataType[]>([]);

  const [invoiceData, setInvoiceData] = useState<TransactionDataType | null>(
    null
  );

  const [currencies, setCurrencies] = useState<CurrencyDataType[]>([]);

  const [filter, setFilter] = useState("user_id");

  const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);

  const now = new Date();
  const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

  const [startDate, endDate] = dateRangeValue || [];

  const { mutate: exportList } = useMutation(client.unidentifiedDeposits.all, {
    onSuccess: (data) => {
      setAlertText("Export success");
      setIsAlertOpened(true);
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  const exportBtn = async () => {
    try {
      setTimeout(() => {
        exportList({
          ...searchParams,
          search_value: searchText || undefined,
          search_field: searchText ? filter : undefined,
          start_date: startDate
            ? moment(startDate).format("yyyy-MM-DD")
            : undefined,
          end_date: endDate ? moment(endDate).format("yyyy-MM-DD") : undefined,
          export: 1,
        });
      }, 300);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const { mutate: getCurrencies } = useMutation(client.currencies.all, {
    onSuccess: (data) => {
      setCurrencies(
        data.map((item) => ({
          key: item.id,
          ...item,
        }))
      );
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  useEffect(() => {
    isMounted && getCurrencies();
  }, [isMounted]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted) {
        getList({
          ...searchParams,
          search_value: searchText.length > 0 ? searchText : undefined,
          search_field: searchText.length > 0 ? filter : undefined,
          start_date: startDate
            ? moment(startDate).format("yyyy-MM-DD")
            : undefined,
          end_date: endDate ? moment(endDate).format("yyyy-MM-DD") : undefined,
        });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [
    isMounted,
    searchParams.current_page,
    searchText,
    filter,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      current_page: 1,
    });
  }, [searchText]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({
      ...searchParams,
      current_page: page,
    });
  };

  const { mutate: getList, isLoading } = useMutation(
    client.unidentifiedDeposits.all,
    {
      onSuccess: (data) => {
        console.log(data);
        setTotal(data.total);

        setDataSource(
          data.data.map((item) => ({
            key: item.id,
            payment_id: item.payment_id,
            external_payment_id: item.external_payment_id,
            user_id: item.user_id,
            full_name: item.full_name,
            reference_code: item.reference_code,
            sender_name: item.sender_name,
            currency: item.asset.code,
            amount: item.amount,
            sender_bank_name: item.sender_bank_name,
            sender_bank_code: item.sender_bank_code,
            sender_iban: item.sender_iban,
            created_at: item.created_at,
            updated_at: item.updated_at,
            admin_id: item.admin_id,
            status: item.status,
            reason: item.reason,
            comment: item.comment,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onCallback = () => {
    getList(searchParams);
  };

  const onAccept = (key: number) => {
    const selData = dataSource.filter((item) => item.key === key)[0];

    openModal("ACCEPT_UNIDENTIFY_DEPOSIT", {
      info: selData,
      callback: onCallback,
    });
  };
  const onReject = (key: number) => {
    const selData = dataSource.filter((item) => item.key === key)[0];

    openModal("REJECT_UNIDENTIFY_DEPOSIT", {
      info: selData,
      callback: onCallback,
    });
  };

  const columns: ColumnsType<UnidentifyDepositDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Payment ID
        </p>
      ),
      dataIndex: "payment_id",
      className: "w-auto",
      width: 100,
      key: "payment_id",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          External payment ID
        </p>
      ),
      dataIndex: "external_payment_id",
      className: "w-auto",
      key: "external_payment_id",
      width: 100,
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          User ID
        </p>
      ),
      dataIndex: "user_id",
      className: "w-auto",
      key: "user_id",
      width: 70,
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Full name
        </p>
      ),
      dataIndex: "full_name",
      className: "w-auto",
      key: "full_name",
      width: 100,
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value?.toUpperCase()}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Reference code
        </p>
      ),
      dataIndex: "reference_code",
      key: "reference_code",
      className: "w-auto",
      render: (value: number) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
          {value}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Sender name
        </p>
      ),
      dataIndex: "sender_name",
      className: "w-auto",
      key: "sender_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Currency
        </p>
      ),
      dataIndex: "currency",
      className: "w-auto",
      key: "currency",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value?.toUpperCase()}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Amount
        </p>
      ),
      dataIndex: "amount",
      className: "w-auto",
      key: "amount",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Payment Method
        </p>
      ),
      dataIndex: "payment_method",
      className: "w-auto",
      key: "payment_method",
      width: 140,
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            Bank transfer
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Bank name
        </p>
      ),
      dataIndex: "sender_bank_name",
      className: "w-auto",
      key: "sender_bank_name",
      width: 100,
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          IBAN
        </p>
      ),
      dataIndex: "sender_iban",
      className: "w-auto",
      key: "sender_iban",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Created
        </p>
      ),
      dataIndex: "created_at",
      className: "w-auto",
      key: "created_at",
      width: 150,
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {moment.unix(value).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Date of confirm
        </p>
      ),
      dataIndex: "updated_at",
      className: "w-auto",
      key: "updated_at",
      width: 150,
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {moment.unix(value).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Admin ID
        </p>
      ),
      dataIndex: "admin_id",
      className: "w-auto",
      key: "admin_id",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Status
        </p>
      ),
      dataIndex: "status",
      className: "w-auto",
      key: "status",
      width: 100,
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className={`font-manrope pt-[4px] pb-[4px] pl-[12px] pr-[12px] rounded-[10px] text-[12px] sm:text-[14px] font-medium ${getStatusStyle(
              value
            )}`}
          >
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Reason for rejection
        </p>
      ),
      dataIndex: "comment",
      className: "w-auto",
      width: 200,
      key: "comment",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <Tooltip
            title={value}
            placement="bottom"
            color="white"
            overlayInnerStyle={{
              color: "#232800",
            }}
          >
            <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium w-[200px] whitespace-nowrap overflow-hidden text-ellipsis">
              {value}
            </p>
          </Tooltip>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Reason
        </p>
      ),
      dataIndex: "reason",
      className: "w-auto",
      width: 200,
      key: "reason",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium w-[200px] whitespace-nowrap overflow-hidden text-ellipsis">
            {splitLetettLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Action
        </p>
      ),
      dataIndex: "action",
      className: "w-[100px]",
      render: (_, record: { key: React.Key; status: string }) => {
        const disabled = record.status !== "unidentified";
        return (
          <div className="flex flex-row gap-4">
            <button
              className={`text-${
                disabled ? "[#BCBEAD]" : "error"
              } text-[14px] font-manrope font-medium`}
              onClick={() => onAccept(record.key as number)}
              disabled={disabled}
            >
              Accept
            </button>
            <button
              className={`text-${
                disabled ? "[#BCBEAD]" : "error"
              } text-[14px] font-manrope font-medium`}
              onClick={() => onReject(record.key as number)}
              disabled={disabled}
            >
              Reject
            </button>
          </div>
        );
      },
    },
  ];

  const mobileColumns: ColumnsType<UnidentifyDepositDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];
        const disabled = selData.status !== "unidentified";

        return (
          <div className="w-full flex flex-row gap-4 items-start justify-between">
            <div className="w-full flex flex-col items-start gap-2">
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Payment ID
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData ? String(selData.payment_id) : "-"}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  User ID
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData ? String(selData.user_id) : "-"}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Reference code
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData.reference_code}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Currency
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData.currency.toUpperCase()}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Payment method
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  Bank transfer
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  IBAN
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData.sender_iban}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Date of confirm
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {moment
                    .unix(selData.updated_at)
                    .format("YYYY-MM-DD HH:mm:ss")}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Status
                </p>
                <p
                  className={`text-primary inline-block mt-0 font-manrope pt-[4px] pb-[4px] pl-[12px] pr-[12px] rounded-[10px] text-[12px] sm:text-[14px] font-medium ${getStatusStyle(
                    selData.status
                  )}`}
                >
                  {selData.status}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Actions
                </p>
                <div className="inline-flex gap-[10px]">
                  <button
                    type="button"
                    className={`text-${disabled ? "[#bcbead]" : "primary"}`}
                    disabled={disabled}
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    className={`text-${disabled ? "[#bcbead]" : "error"}`}
                    disabled={disabled}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-2">
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  External payment ID
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData.external_payment_id}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Full name
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData.full_name}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Sender name
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData.sender_name}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Amount
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData.amount}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Bank name
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData.sender_bank_name}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Created
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {moment
                    .unix(selData.created_at)
                    .format("YYYY-MM-DD HH:mm:ss")}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Admin ID
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0">
                  {selData.admin_id}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Reason for rejection
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0 w-[200px] whitespace-normal">
                  {selData.reason}
                </p>
              </div>
              <div className="w-full">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Reason
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium mt-0 w-[200px] whitespace-normal">
                  {selData.comment}
                </p>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full flex flex-col">
        <div
          className={`${
            invoiceData && "hidden"
          } w-full h-full flex-col p-2 sm:p-4`}
        >
          <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
            Unidentified Deposits
          </p>

          <div className="mt-4 lg:flex items-center justify-between w-full mb-4">
            <SearchFilter
              options={unidentDepositFilterOptions}
              value={filter}
              onChange={setFilter}
              searchText={searchText}
              setSearchText={setSearchText}
              currencies={currencies}
            />
            <div className="sm:flex gap-2">
              <DateRangePicker
                format="dd/MM/yyyy"
                size="lg"
                block
                placement="bottomEnd"
                style={{ width: "308px" }}
                value={dateRangeValue}
                onChange={setDateRangeValue}
                defaultCalendarValue={[prevMonth, now]}
                ranges={[]}
              />
              <DateRangePicker
                format="HH:mm"
                value={dateRangeValue}
                onChange={setDateRangeValue}
              />
              <button
                type="button"
                onClick={() => exportBtn()}
                className="button button--type3 button--gap30"
              >
                Export Excel
                <span className="btn-icon btn-icon--fill">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.79961 1.8002L12.6996 5.4002M8.79961 1.8002L5.19961 5.4002M8.79961 1.8002L8.79961 13.2002M15.9996 8.4002V16.2002L1.59961 16.2002L1.59961 8.4002"
                      stroke="#232800"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
            <Table
              loading={isLoading}
              showSorterTooltip={false}
              columns={isMobile ? mobileColumns : columns}
              dataSource={dataSource}
              pagination={{
                total,
                current: searchParams.current_page,
                onChange: onPageChange,
                position: ["bottomCenter"],
                defaultPageSize: LIST_PAGE_SIZE,
                showSizeChanger: false,
              }}
              scroll={{ x: "max-content" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnidentifiedDepositsPage;
